import { GET_SKILLSETS_QUERY, type GetSkillsetsData, type GetSkillsetsVariables } from '~/graphql/queries/skillsets';
import {
    ShiftSkillsetType,
    type ShiftPositionType,
    type SkillsetType,
    type UserType,
} from '~/generated/types';
import type { CreateAccreditationVariables } from '~/graphql/mutations/accreditations';

export const getSkillsets = async(): Promise<SkillsetType[]> => {
    const { data } = await useAsyncQuery<GetSkillsetsVariables, GetSkillsetsData>(GET_SKILLSETS_QUERY);

    return data.value?.skillsets.edges.map(({ node }) => node) || [];
};


export const canRegisterInterest = (user: UserType | null | undefined, position: ShiftPositionType, skillset: SkillsetType | undefined = undefined): boolean => {
    return hasRequiredSkillset(user, position, skillset) && hasRequiredAccreditations(user, position);
};

export const hasRequiredSkillset = (user: UserType | null | undefined, position: ShiftPositionType, skillset: SkillsetType | undefined = undefined): boolean => {
    if (position.skillsetType === ShiftSkillsetType.MinimumSkillset) {
        return position.requiredSkillsets[0]?.order <= (user?.skillset?.order || 0);
    } else if (position.skillsetType === ShiftSkillsetType.SkillsetChoices) {
        return skillset?.id === user?.skillset?.id;
    }

    return false;
};

export const hasRequiredAccreditations = (user: UserType | null | undefined, position: ShiftPositionType): boolean => {
    if (position.requiredAccreditations.length === 0) {
        return true;
    }

    return position.requiredAccreditations.every(accreditation => {
        return user?.accreditations.some(userAccreditation => {
            return userAccreditation.id === accreditation.id;
        });
    });
};
